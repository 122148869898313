<template>
  <auth-content>
    <el-header slot="header" :title="$t(module + '.title')">
      <div slot="actions"  v-if="actionsLoaded && actions && actions.length > 0">

        <div class="btn-group btn-group-sm">
          <button type="button" :class="{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary text-white': actionViewMode == 'list',
            'btn-secondary': actionViewMode != 'list',
          }" @click="$store.commit('actionsboard/setActionViewMode', 'list')"><i class="mr-1 d-none d-md-inline icon-list3"></i> {{ $t(module + '.header.button_list_view') }}</button>
          <button type="button" :class="{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary': actionViewMode == 'kanban',
            'btn-secondary': actionViewMode != 'kanban',
          }" @click="$store.commit('actionsboard/setActionViewMode', 'kanban')"><i class="mr-1 d-none d-md-inline icon-windows2"></i> {{ $t(module + '.header.button_kanban_view') }}</button>
          <button type="button" :class="{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary': actionViewMode == 'calendar',
            'btn-secondary': actionViewMode != 'calendar',
          }" @click="$store.commit('actionsboard/setActionViewMode', 'calendar')"><i class="mr-1 d-none d-md-inline icon-calendar22"></i> {{ $t(module + '.header.button_calendar_view') }}</button>
          <button type="button" :class="{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary': actionViewMode == 'gantt',
            'btn-secondary': actionViewMode != 'gantt',
          }" @click="$store.commit('actionsboard/setActionViewMode', 'gantt')"><i class="mr-1 d-none d-md-inline icon-paragraph-right3
"></i> {{ $t(module + '.header.button_gantt_view') }}</button>
        </div>

      </div>
    </el-header>

    <el-filters :filters="filters" v-if="actionsLoaded && actions && actions.length > 1" />

    <div class="">
      <TodoCreate :users="users" ref="childCreate" :priorities="priorities" :formatSelectedDate="formatSelectedDate"> </TodoCreate>

      <div v-if="!actionsLoaded">
        <div class="pt-2">
          <div class="falseaction card bg-white mb-2 align-items-center justify-content-between d-flex" v-for="i in 7" :style="'animation-delay: .'+(i)+'s'">
            <div class="bg-secondary falsecheckbox"></div>
            <div class="bg-secondary falsetext"></div>
            <div class="align-items-center justify-content-between d-flex">
              <div class="bg-secondary falsepeople"></div>
              <i class="icon-star-full2 text-secondary mx-3"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="actionsLoaded && actions && actions.length > 0">

        <div class="row">
          <div
            :class="{
              'col-md-8 col-xl-9 col-xxl-10': onShowDetail,
              'col-12': !onShowDetail,
            }"
          >

            <div v-if="actionViewMode === 'list'">
              <TodoActionsList
                :formatSelectedDate="formatSelectedDate"
                :actions="filteredActions"
                @onShowDetailTodo="onShowDetailTodo"
                :pdca_values="pdca_values"
                :priorities="priorities"
                :users="users"
              />
            </div>
            <div v-if="actionViewMode === 'kanban'">
              <TodoActionsKanban
                :actions="filteredActions"
                @onShowDetailTodo="onShowDetailTodo"
                :pdca_values="pdca_values"
                :priorities="priorities"
                :users="users"
              />
            </div>
            <div v-if="actionViewMode === 'calendar'">
              <TodoActionsCalendar
                :actions="filteredActions"
                @onShowDetailTodo="onShowDetailTodo"
                :pdca_values="pdca_values"
                :priorities="priorities"
                :users="users"
              />
            </div>
            <div v-if="actionViewMode === 'gantt'">
              <TodoActionsGantt
                :actions="filteredActions"
                @onShowDetailTodo="onShowDetailTodo"
                :pdca_values="pdca_values"
                :priorities="priorities"
                :users="users"
              />
            </div>

          </div>

          <div
            :class="{
              'col-md-4 col-xl-3 col-xxl-2': true,
            }"
            v-if="onShowDetail"
          >
            <TodoDetail
              v-if="tagsLoaded"
              ref="childDetail"
              :data="dataDetailListing" @updateTodo="updateTodo"
              @updateTodoData="updateTodoData"
              @unselectTask="onShowDetailTodo"
              :users="users"
              :priorities="priorities"
              :formatSelectedDate="formatSelectedDate"
              v-click-outside="clickOutsideTask"
            ></TodoDetail>
          </div>
        </div>

      </div>
      <el-empty v-else :title="$t(module + '.actions.empty_title')" :text="$t(module + '.actions.empty_text')" />
    </div>

    <TagData @changeResponsable="changeResponsable" @datepickerNewTodoEmit="datepickerNewTodoEmit" @AddTagEmit="AddTagEmit" @refreshDataTags="refreshDataTags"></TagData>

  </auth-content>
</template>

<script>

import Fuse from 'fuse.js'
import vClickOutside from 'v-click-outside'
import component from './component'
import store from '@/store'
import _ from 'lodash'
import moment from 'moment'

import {
ACTION_REQUEST_ACTIONS_CREATOR,
ACTION_REQUEST_ACTIONS_STORE,
ACTION_ACTIVE_OWNER,
ACTION_REQUEST_ACTIONS_CREATOR_HISTORIQ,
ACTION_REQUEST_ACTIONS_CREATOR_PROCESS,
ACTION_REQUEST_ACTIONS_CREATOR_FAVORITE,
ACTION_REQUEST_ACTIONS_CREATOR_ASSIGN,
ACTION_REQUEST_ACTIONS_CREATOR_OWN,
ACTION_REQUEST_ACTIONS_CREATOR_RECCURENCE,
ACTION_REQUEST_TAGS,
WORLD_TRIBES_REQUEST
} from '../mutations-types'
/**
 * @group _ Module Actionsboard
 * This component display page actions board
 */
export default {
  name: 'ActionsIndex',
  components: {
    TodoCreate: component.TodoCreate,
    TagData: component.TagData,
    TodoActionsList: component.TodoActionsList,
    TodoActionsKanban: component.TodoActionsKanban,
    TodoActionsCalendar: component.TodoActionsCalendar,
    TodoActionsGantt: component.TodoActionsGantt,
    TodoDetail: component.TodoDetail,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data () {
    return{
      clickOutsideTask: {
        handler: () => { this.onShowDetail = false },
        // middleware: this.clickOutsideTaskMiddleware,
        events: ['click']
      },
      data: [],
      listTagsFilters: {
        tag: [],
        list: [],
        project: [],
        model: [],
        tribus: [],
      },
      onShowDetail: false,
      dataDetailListing: {},
      module: 'modules.' + this.$route.meta.moduleName,
      filters: [],
      respons: null,
      actionsLoaded: false,
      tagsLoaded: true,
      pdca_values: ['plan', 'do', 'check', 'act'],
      priorities: {
        high: {
          title: this.$t('todos.priorities.high'),
          icon: 'icon-arrow-up16',
          class: 'danger',
          number: 40,
        },
        medium: {
          title: this.$t('todos.priorities.medium'),
          icon: 'icon-arrow-up7',
          class: 'orange',
          number: 30,
        },
        low: {
          title: this.$t('todos.priorities.low'),
          icon: 'icon-arrow-up7',
          class: 'yellow',
          number: 20,
        },
        none: {
          title: this.$t('todos.priorities.none'),
          icon: 'icon-arrow-down7',
          class: 'grey',
          number: 10,
        }
      },
    }
  },
  mounted () {
    if (this.cantLoadPage) {
      return
    }
    // Load Actions
    this.$store.dispatch('actionsboard/' + ACTION_REQUEST_ACTIONS_CREATOR, {id: this.$route.params.world, entity: 'Actions'}).then(() => {
      this.actionsLoaded = true
    })

    this.loadFilters()
    this.applyDataFilters(this.formattedActions, this.filters)

    //select my actions filter
    let indexTypeFilter = _.findIndex(Object.keys(this.filters), opt => {
      return this.filters[opt].key == 'type'
    })
    if (indexTypeFilter != -1) {
      let indexMyActionsFilter = _.findIndex(Object.keys(this.filters[indexTypeFilter].options), opt => {
        return this.filters[indexTypeFilter].options[opt].title == this.$t(this.module + '.filters.type_actions.options.ACTION_REQUEST_ACTIONS_CREATOR_OWN')
      })
      if (indexMyActionsFilter != -1) {
        this.filters[indexTypeFilter].options[indexMyActionsFilter].value = true
        this.$store.commit('global/addSidebarFilter', {
          key: 'type',
          value: 'ACTION_REQUEST_ACTIONS_CREATOR_OWN',
        })
      }
    }

    this.$store.dispatch('actionsboard/' + ACTION_REQUEST_TAGS, {id: this.$store.getters['auth/getWorld'].id, entity: 'Tags', type: 'all'}).then(tags => {
      this.$store.dispatch('actionsboard/' + WORLD_TRIBES_REQUEST, this.$store.getters['auth/getWorld'].id).then(tribus => {
      })
    })
    this.changeResponsable
  },
  methods: {
    loadFilters () {
      let content = {...this.formatActions()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t(this.module + '.filters.type_actions.title'),
          key: 'type',
          options: this.getFilterOptions(content, 'type', this.$t(this.module + '.filters.type_actions.options')),
        },
        {
          title: this.$t(this.module + '.filters.date.title'),
          key: 'date_filter',
          options: this.getFilterOptions(content, 'date_filter', this.$t(this.module + '.filters.date.options')),
        },
        {
          title: this.$t(this.module + '.filters.tags'),
          key: 'tag',
          options: this.listTagOptions(content),
        },
        {
          title: this.$t(this.module + '.filters.owner'),
          key: 'owner_filter',
          options: this.getFilterOptions(content, 'owner_filter'),
        },
        {
          title: this.$t(this.module + '.filters.project'),
          key: 'project_filter',
          options: this.getFilterOptions(content, 'project_filter'),
        },
      ])
    },
    formatSelectedDate(val, placeholder = null) {
      let currentYear = new Date().getFullYear()
      // val = val.replace('/' + currentYear, '').replace('/' + currentYear, '')
      if (val == '' || val == null) {
        if (!placeholder) {
          return '<i class="icon-calendar22 text-grey"></i>'
        } else {
          return placeholder
        }
      } else {
        let vals = val.split(' - ')
        vals[0] = moment(vals[0], "DD/MM/YYYY")
        vals[1] = moment(vals[1], "DD/MM/YYYY")
        if (vals[0].toString() === vals[1].toString()) {
          val = this.$d(vals[0].toDate(), vals[0].format('YYYY') != currentYear ? 'shortText' : 'shortTextWoYear')
        } else {
          vals[0] = this.$d(vals[0].toDate(), vals[0].format('YYYY') != currentYear ? 'shortText' : 'shortTextWoYear')
          vals[1] = this.$d(vals[1].toDate(), vals[1].format('YYYY') != currentYear ? 'shortText' : 'shortTextWoYear')
          val = vals.join(' - ')
        }
      }
      return val.replace('-', '<span class="text-grey">→</span>')
    },
    /**
     * @vuese
     * get title tags actions
     * @arg
     */
    listTagOptions (content) {
      let selectedTags = this.getFilterOptions(content, 'tag')
      let existing = []
      selectedTags.map(item => {
        this.tags.tag.map(tag => {
          if(item.raw_values[0] == tag.id && !existing.includes(tag.id)) {
            existing.push(tag.id)
            item.title = tag.name
          }
        })
      })
      return selectedTags
    },
    /**
     * @vuese
     * how detail todo action
     * @arg
     */
    onShowDetailTodo (task, status) {
      if(task.done != true) {
        this.dataDetailListing = task
        this.onShowDetail = status
        if(status)
          $('.taskSelectedIs_'+task.id).addClass('detailsIsShow')
        else
          $('.newTaskTitle_').removeClass('detailsIsShow')
      }
    },
    /**
     * @vuese
     * update data
     * @arg object
     */

    updateTodo (elem) {
      this.data.filter(item => {
        if(item.id === elem.id) {
          item[elem.key] = elem.value
        }
      })
    },
    /**
     * @vuese
     * hide todoDetail
     * @arg
     */

    updateTodoData () {
      this.onShowDetailTodo({}, false)
    },
    // change responsable from tag
    /**
     * @vuese
     * update USER added by tagDATA
     * @arg
     */
    changeResponsable (responsable) {
      this.$refs.childCreate.responsibleTask(responsable)
    },
    /**
     * @vuese
     * update date added by tagDATA
     * @arg
     */
    datepickerNewTodoEmit (date) {
      this.$refs.childCreate.datepickerNewTodo(date)
    },
    /**
     * @vuese
     * Upadate tag added by tagDATA
     * @arg
     */

    AddTagEmit (tag) {
      !tag.create ? this.$refs.childDetail.AddTagCreate(tag): this.$refs.childCreate.AddTagCreate(tag)
    },
    /**
     * @vuese
     * send to filter date
     * @arg
     */

    filterByDate (e, date) {
      e.preventDefault()
      this.filteredByDate(date)
    },
    /**
     * @vuese
     * Refresh data tag in detail component
     * @arg
     */
    refreshDataTags () {
      this.$refs.childDetail.refreshTags()
      this.tagsLoaded = false
      this.$nextTick(() => {
       this.tagsLoaded = true
      });
    },

    /**
     * @vuese
     * this function store filter by users and tags
     * @arg
     */
    filterByTags (filters) {
      this.filtersByTags = filters
      this.$store.commit('actionsboard/setFilters',{key: 'filterGlobal', value: [...this.filtersByUsers, ...this.filtersByTags] })
    },

    formatActions () {
      return _.cloneDeep(this.actions).map(action => {
        // owner
        if (action.owner) {
          action['owner_filter'] = this.users.find(user => { return user.id == action.owner }) ? this.users.find(user => { return user.id == action.owner }).rendered_name : ''
        } else {
          action['owner_filter'] = ''
        }

        // project
        if (action.project && action.project.length > 0) {
          action['project_filter'] = this.tags.project.find(tag => { return tag.id == action.project }) ? this.tags.project.find(tag => { return tag.id == action.project }).name : ''
        } else {
          action['project_filter'] = ''
        }

        // type
        action['type'] = []
        if (action.creator === this.currentUser.id && action.owner !== action.creator) {
          action['type'].push(ACTION_REQUEST_ACTIONS_CREATOR_ASSIGN)
        }
        if (action.done) {
          action['type'].push(ACTION_REQUEST_ACTIONS_CREATOR_HISTORIQ)
        }
        if (action.favorite) {
          action['type'].push(ACTION_REQUEST_ACTIONS_CREATOR_FAVORITE)
        }
        if (action.actionRecurrence) {
          action['type'].push(ACTION_REQUEST_ACTIONS_CREATOR_RECCURENCE)
        }
        if (action.owner == this.currentUser.id && !action.done) {
          action['type'].push(ACTION_REQUEST_ACTIONS_CREATOR_OWN)
        }
        if (action.process) {
          action['type'].push(ACTION_REQUEST_ACTIONS_CREATOR_PROCESS)
        }

        // date_filter
        let today = moment()
        action['date_filter'] = []
        if (action.startDate != null) {
          if (moment(action.startDate).isSame(today, 'day') || moment(action.endDate).isSame(today, 'day')) {
            action['date_filter'].push('today')
          }
          if (moment(action.startDate).isSame(today, 'week') || moment(action.endDate).isSame(today, 'week')) {
            action['date_filter'].push('same_week')
          }
          if (moment(action.startDate).isSame(today, 'month') || moment(action.endDate).isSame(today, 'month')) {
            action['date_filter'].push('same_month')
          }
        }
        else {
          action['date_filter'].push('empty')
        }

        return action
      })
    },

  },
  computed : {
    cantLoadPage () {
      return !this.$store.getters['auth/hasWorld'] || (!this.$store.getters['auth/worldOnTrial'] && !this.$store.getters['auth/worldHasSubscription'])
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    actions () {
      return this.$store.state.actionsboard.todos
    },
    users () {
      let users = this.$store.getters['world/getUsers']
      if (!users) {
        users = []
      }
      users.forEach(user => {
        user.rendered_name = user.full_name || user.email
      })
      return users
    },
    actionViewMode () {
      return this.$store.state.actionsboard.actionViewMode
    },
    formattedActions () {
      return this.formatActions()
    },
    filteredActions () {
      if (this.searchValue === '') {
        return this.applyDataFilters(this.formattedActions, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.formattedActions, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
        ]
      }).search(this.searchValue)
    },
    searchValue: {
      get () {
        return this.$store.state.global.sidebarSearch
      },
      set (value) {
        this.$store.commit('global/changeSidebarSearch', value)
      }
    },
    /**
     * @vuese
     *  get all type tags from store
     * @arg
     */
    tags () {
      return {
        tag: this.$store.state.actionsboard.tagsTag,
        list: this.$store.state.actionsboard.tagsList,
        project: this.$store.state.actionsboard.tagsProject,
        model: this.$store.state.actionsboard.tagsModel,
        tribus: this.$store.state.actionsboard.tagsTribes,
      }
    }
  },
  watch: {
    actions: {
      handler: function (val, oldVal) {
        this.loadFilters()
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .sidebar-right-content {
   position:static !important;
}
@keyframes flickerAnimation {
  0%   { opacity:.8; }
  50%  { opacity:0.4; }
  100% { opacity:.8; }
}
.falseaction {
  animation: flickerAnimation 2s infinite;
  flex-direction: initial;
  .falsecheckbox {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin: 0.75rem 0.875rem;
  }
  .falsetext {
    height: 12px;
    opacity: .1;
    width: 75%;
  }
  .falsepeople {
    width: 20px;
    height: 20px;
    border-radius: 100px;
  }
  .icon-star-full2 {
    opacity: .3;
  }
}
</style>
